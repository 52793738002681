import UserManager from "./UserManager";
import ApiConstants from "../constants/ApiConstants";
import RouteManager, { ROUTE_ID_IDENTIFIER } from "./RouteManager";
import { PAGE_ROUTES } from "./RouteManager";
import { showToast, TOAST_TYPE } from "deskera-ui-library";
import TicketManager from "./TicketManager";
import Utility from "../utility/Utility";

import { LOCAL_STORAGE_KEYS } from "../../src/constants/Enum";

const DEAL_VIEW_INDEX = {
  KANBAN: "0",
  GRID: "1"
};

export default class CoPilotManager {
  static moduleData = null;

  static modules = {
    dashboard: {
      title: "Dashboard",
      route: PAGE_ROUTES.DASHBOARD
    },
    contact: {
      title: "Contact",
      route: PAGE_ROUTES.CONTACTS
    },
    deal: {
      title: "Deal",
      route: PAGE_ROUTES.DEALS
    },
    contact_ui: {
      title: "Contact Details",
      route: PAGE_ROUTES.CONTACTS_UI
    },
    deal_ui: {
      title: "Deal Details",
      route: PAGE_ROUTES.DEALS_UI
    },
    account_ui: {
      title: "Account Details",
      route: PAGE_ROUTES.ACCOUNT_DETAILS
    },
    list: {
      title: "List",
      route: PAGE_ROUTES.LIST
    },
    account: {
      title: "Accounts",
      route: PAGE_ROUTES.ACCOUNTS
    },
    campaign: {
      title: "Campaigns",
      route: PAGE_ROUTES.CAMPAIGN
    },
    landingPages: {
      title: "Landing Pages",
      route: PAGE_ROUTES.LANDING_PAGE
    },
    forms: {
      title: "Forms",
      route: PAGE_ROUTES.FORMS
    },
    tickets: {
      title: "Support Tickets",
      route: PAGE_ROUTES.SUPPORT_TICKET
    }
  };

  static getModuleRoute(moduleName) {
    // console.log(`getModuleRoute called with moduleName: ${moduleName}`);
    const route = this.modules[moduleName]?.route || null;
    // console.log(`Route found: ${route}`);
    return route;
  }

  static setModuleData(moduleName, filterData) {
    // console.log(`setModuleData called with moduleName: ${moduleName}, filterData:`, filterData);
    this.moduleData = { moduleName, filterData };
    // console.log(`Module data set to:`, this.moduleData);
  }

  static currentemaildata = {};
  static onEmailDataChange = null;

  static setEmailData(newEmailData) {
    // console.log(`setEmailData called with newEmailData:`, newEmailData);
    this.currentemaildata = newEmailData;
    // console.log(`Current email data set to:`, this.currentemaildata);
    if (this.onEmailDataChange) {
      // console.log(`onEmailDataChange is a valid callback. Calling it with newEmailData.`);
      this.onEmailDataChange(newEmailData);
    }
  }

  static navigateToRoute(moduleData) {
    // console.log(`navigateToRoute called with moduleData:`, moduleData, this.modules);
    const module = CoPilotManager.modules[moduleData.moduleName];

    if (!module) {
      // console.log(`Invalid module name: ${moduleData.moduleName}`);
      showToast("Invalid module name", TOAST_TYPE.ERROR);
      return;
    }

    // console.log(`Valid module found:`, module);
    if (
      moduleData.moduleName ===
      CoPilotManager.modules.tickets.title.toLowerCase()
    ) {
      // console.log(`Navigating to tickets route: ${module.route}`);
      RouteManager.navigateToPage(module.route);
      if (moduleData.ticket) {
        // console.log(`Ticket data present. Setting selected ticket:`, moduleData.ticket);
        TicketManager.setSelectedTicket(moduleData.ticket);
        showToast(
          "Now reading ticket: " + moduleData.ticket.id,
          TOAST_TYPE.SUCCESS
        );
      }
    } else {
      if (moduleData.filteredData) {
        // console.log(`Filtered data present. Navigating to route: ${module.route}`);
        RouteManager.navigateToPage(module.route);
        if (
          CoPilotManager.modules[moduleData.moduleName] &&
          moduleData.moduleName ===
            CoPilotManager.modules[moduleData.moduleName].title.toLowerCase()
        ) {
          // console.log(`Setting persistent value for selected ${moduleData.moduleName} view to GRID.`);

          Utility.setPersistentValue(
            LOCAL_STORAGE_KEYS.SELECTED_DEAL_VIEW,
            DEAL_VIEW_INDEX.GRID
          );
        }
        RouteManager.navigateToPage(module.route);
        CoPilotManager.setModuleData(
          moduleData.moduleName,
          moduleData.filteredData
        );
      } else if (moduleData.recordId) {
        // console.log(`Record ID present. Getting module route for UI.`);
        const route = CoPilotManager.getModuleRoute(
          moduleData.moduleName + "_ui"
        );
        const recordRoute = route.replace(
          ROUTE_ID_IDENTIFIER,
          moduleData.recordId
        );
        // console.log(`Navigating to record route: ${recordRoute}`);
        RouteManager.navigateToPage(recordRoute);
      } else {
        // console.log(`No filtered data or record ID. Getting module route.`);
        const route = CoPilotManager.getModuleRoute(moduleData.moduleName);
        // console.log(`Navigating to route: ${route}`);
        RouteManager.navigateToPage(route);
      }
    }
    showToast("Data loaded successfully", TOAST_TYPE.SUCCESS);
  }

  static showCoPilot() {
    // console.log(`showCoPilot called. Initializing deskeraChat with tenantId, accentColor, appName, accessToken.`);
    window.deskeraChat("init", {
      tenantId: UserManager.getUserTenantID(),
      accentColor: "#3f0e40",
      appName: "CRM",
      accessToken: ApiConstants.ACCESS_TOKEN,
      onNavigate: CoPilotManager.navigateToRoute
    });
    // console.log(`deskeraChat initialized.`);
  }
}

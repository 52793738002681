import ic_trigger_campaign_published from "../../assets/menu/white/ic_campaign.png";
import ic_trigger_form_sent from "../../assets/menu/white/ic_form.png";
import ic_trigger_record_added from "../../assets/menu/white/ic_user.png";
import ic_trigger_field_value from "../../assets/menu/white/ic_list.png";

import ic_whatsapp from "../../assets/automation/actions/ic_whatsapp.png";
import ic_deals from "../../assets/menu/white/ic_deals.png";
import ic_split from "../../assets/icons/white/ic_split.png";
import ic_owner from "../../assets/automation/ic_assign_1.png";
import ic_quote from "../../assets/menu/filled/ic_quote.svg";
import ic_support from "../../assets/menu/filled/Support-Ticket-White-01.svg";

import { DKIcon, DKIcons, INPUT_TYPE } from "deskera-ui-library";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import Utility, {
  getRandomAlphaNumericString,
  replaceCountrySpecificKeywords
} from "../../utility/Utility";
import AutomationService from "../../services/automation";
import {
  AUTOMATION_ACTIONS,
  AUTOMATION_ELEMENT,
  AUTOMATION_TRIGGERS,
  WAIT_INTERVALS,
  STATUS_OPTIONS,
  SPLIT_GUTTER,
  STEP_CARD_WIDTH,
  DIRECT_CHILD_ACTION_TYPES,
  OBJECT_TYPES,
  SPLIT_TYPE_ACTIONS
} from "./AutomationConstants";

export default class AutomationManager {
  static automationData;
  static steps = [];
  static adjacencyMatrix = {};
  static uiJson = {
    wingSpan: {},
    outerRadiusFromLeft: 0,
    outerRadiusFromRight: 0
  };

  static connectionId = null;
  static fbForms = [];
  static fbPages = [];
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getAllTriggers() {
    let quoteCreated = replaceCountrySpecificKeywords(`Quote created`);

    return [
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.FORM_SUBMITTED,
        title: "Form submitted"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.CONTACT_ADDED,
        title: "Contact added"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.CAMPAIGN_PUBLISHED,
        title: "Campaign is published"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.VALUE_CHANGED,
        title: "Contact field changed"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED,
        title: "Deal field changed"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED,
        title: "Deal stage changed"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED,
        title: "Activity field changed"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.QUOTE_CREATED,
        title: quoteCreated
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.DATE_ALERT,
        title: "Date alert trigger"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.TICKET_CREATED,
        title: "Ticket created"
      },
      {
        elementType: AUTOMATION_ELEMENT.TRIGGER,
        type: AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED,
        title: "Ticket field changed"
      }
      // {
      //   elementType: AUTOMATION_ELEMENT.TRIGGER,
      //   type: AUTOMATION_TRIGGERS.FACEBOOK_LEAD_ADDED,
      //   title: "Facebook Lead Sync",
      // },
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getAllActions(objectTypes = [OBJECT_TYPES.CONTACT]) {
    const actions = [
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.SEND_EMAIL,
        title: "Send an e-mail"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE,
        title: "Send a whatsapp message"
      },
      // {
      //   elementType: AUTOMATION_ELEMENT.ACTION,
      //   type: AUTOMATION_ACTIONS.SEND_SMS_MESSAGE,
      //   title: "Send a text message",
      // },
      // {
      //   elementType: AUTOMATION_ELEMENT.ACTION,
      //   type: AUTOMATION_ACTIONS.CREATE_CONTACT,
      //   title: "Create a contact"
      // },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.UPDATE_CONTACT,
        title: "Update contact"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CREATE_DEAL,
        title: "Create a deal"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.UPDATE_DEAL,
        title: "Update deal"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CRM_APPROVAL,
        title: "Approval condition"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.DEAL_UPDATE_APPROVAL,
        title: "Approval condition"
      }
    ];

    objectTypes?.forEach((objectType) => {
      actions.push({
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.ASSIGN_OWNER,
        title: `Assign ${objectType.toLowerCase()} owner`,
        configuration: {
          users: [],
          objectType
        }
      });
    });

    actions.push(
      ...[
        {
          elementType: AUTOMATION_ELEMENT.ACTION,
          type: AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
          title: "Split path",
          logicalOperator: "and",
          successKey: null,
          failureKey: null
        },
        {
          elementType: AUTOMATION_ELEMENT.ACTION,
          type: AUTOMATION_ACTIONS.WAIT_FOR_TIME,
          title: "Wait for (time)"
        },
        {
          elementType: AUTOMATION_ELEMENT.ACTION,
          type: AUTOMATION_ACTIONS.END_WORKFLOW,
          title: "End automation"
        }
      ]
    );

    return actions;
  }

  static getFacebookActions() {
    return [
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY,
        title: "Create a contact",
        tableName: TABLES.CONTACT
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY,
        title: "Create a deal",
        tableName: TABLES.DEAL
      }
    ];
  }

  static getTicketActions(actionType) {
    let actions = [...actionType];
    let ticketActions = [
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.UPDATE_TICKET,
        title: "Update ticket"
      },
      {
        elementType: AUTOMATION_ELEMENT.ACTION,
        type: AUTOMATION_ACTIONS.WAIT_TILL,
        title: "Pause or split by"
      }
    ];
    actions.splice(actionType.length - 1, 0, ...ticketActions);
    return actions;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getTriggerIcon(trigger) {
    let icon = ic_trigger_form_sent;
    switch (trigger) {
      case AUTOMATION_TRIGGERS.CAMPAIGN_PUBLISHED:
        icon = ic_trigger_campaign_published;
        break;
      case AUTOMATION_TRIGGERS.FORM_SUBMITTED:
        icon = ic_trigger_form_sent;
        break;
      case AUTOMATION_TRIGGERS.CONTACT_ADDED:
        icon = ic_trigger_record_added;
        break;
      case AUTOMATION_TRIGGERS.VALUE_CHANGED:
        icon = DKIcons.white.ic_edit;
        break;
      case AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED:
        icon = DKIcons.white.ic_edit;
        break;
      case AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED:
        icon = DKIcons.white.ic_edit;
        break;
      case AUTOMATION_TRIGGERS.FACEBOOK_LEAD_ADDED:
        icon = DKIcons.white.ic_facebook;
        break;
      case AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED:
        icon = ic_deals;
        break;
      case AUTOMATION_TRIGGERS.QUOTE_CREATED:
        icon = ic_quote;
        break;
      case AUTOMATION_TRIGGERS.TICKET_CREATED:
      case AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED:
        icon = ic_support;
        break;
      case AUTOMATION_TRIGGERS.DATE_ALERT:
        icon = DKIcons.white.ic_notification;
        break;
      default:
        icon = ic_trigger_field_value;
    }
    return (
      <DKIcon
        src={icon}
        className={` ${
          trigger === AUTOMATION_TRIGGERS.FACEBOOK_LEAD_ADDED
            ? "ic-r-3 p-xs"
            : "ic-s-2 bg-button p-s "
        }   border-radius-m`}
      />
    );
  }
  static getActionIcon(action) {
    let icon = DKIcons.white.ic_email;
    let color = "bg-blue";
    switch (action.type) {
      case AUTOMATION_ACTIONS.SEND_EMAIL:
        icon = DKIcons.white.ic_email;
        color = "bg-gray";
        break;
      case AUTOMATION_ACTIONS.SEND_SMS_MESSAGE:
        icon = DKIcons.white.ic_notification;
        color = "bg-telegram";
        break;
      case AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE:
        icon = ic_whatsapp;
        color = "bg-whats-app";
        break;
      case AUTOMATION_ACTIONS.CREATE_CONTACT:
        icon = DKIcons.white.ic_user;
        color = "bg-blue";
        break;
      case AUTOMATION_ACTIONS.UPDATE_CONTACT:
        icon = DKIcons.white.ic_edit;
        color = "bg-blue";
        break;
      case AUTOMATION_ACTIONS.CREATE_DEAL:
        icon = ic_deals;
        color = "bg-green";
        break;
      case AUTOMATION_ACTIONS.UPDATE_DEAL:
        icon = DKIcons.white.ic_edit;
        color = "bg-green";
        break;
      case AUTOMATION_ACTIONS.SPLIT_WORKFLOW:
        icon = ic_split;
        color = "bg-dark-gray";
        break;
      case AUTOMATION_ACTIONS.WAIT_TILL:
        icon = ic_split;
        color = "bg-yellow";
        break;
      case AUTOMATION_ACTIONS.WAIT_FOR_TIME:
        icon = DKIcons.white.ic_notification;
        color = "bg-yellow";
        break;
      case AUTOMATION_ACTIONS.ASSIGN_OWNER:
        icon = ic_owner;
        color = "bg-orange";
        break;
      case AUTOMATION_ACTIONS.END_WORKFLOW:
        icon = DKIcons.white.ic_warning;
        color = "bg-red";
        break;
      case AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY:
        if (action.tableName === "deal") {
          icon = ic_deals;
          color = "bg-green";
        } else {
          icon = DKIcons.white.ic_user;
          color = "bg-blue";
        }
        break;
      case AUTOMATION_ACTIONS.UPDATE_TICKET:
        icon = DKIcons.white.ic_edit;
        color = "bg-orange";
        break;
      default:
        icon = DKIcons.white.ic_email;
    }
    return (
      <DKIcon src={icon} className={"ic-s-2 p-s border-radius-m " + color} />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getWaitIntervals = (index, interval) =>
    `${interval} ${WAIT_INTERVALS[index]}${interval === 1 ? "" : "s"}`;

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static getDefaultActionData(action) {
    return [];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static addNewStep(
    stepData,
    prevStepId,
    indexToAdd = null,
    splitIndex = 0,
    isNewWaitNodeRequired = false
  ) {
    const newStepId = getRandomAlphaNumericString(24);

    if (indexToAdd !== null) {
      this.steps.splice(indexToAdd + splitIndex + 1, 0, {
        ...stepData,
        id: newStepId
      });
    } else {
      this.steps.push({ ...stepData, id: newStepId });
    }

    this.setAdjacencyMatrix(
      prevStepId,
      newStepId,
      SPLIT_TYPE_ACTIONS.includes(stepData.type),
      splitIndex
    );

    if (isNewWaitNodeRequired) {
      /* Setting a default wait action (1 day) before Email based split conditions */
      const newWaitNode = AutomationManager.getAllActions().find(
        (actionData) => actionData.type === AUTOMATION_ACTIONS.WAIT_FOR_TIME
      );
      newWaitNode["configuration"] = {
        minutes: 1440,
        count: 1,
        intervalIndex: 0
      };

      this.addNewStep(newWaitNode, prevStepId, indexToAdd, splitIndex);
    }

    this.generateUIJson();

    return { id: newStepId };
  }

  static updateStep(stepData, indexToUpdate) {
    /* To handle edit operation on END_WORKFLOW */
    if (!stepData.id) {
      stepData.id = this.steps[indexToUpdate].id;
    }

    this.steps[indexToUpdate] = stepData;
  }

  /**
   * @param stepInfo
   * @description stepInfo contains current step, it's parent & it's linking status related details in keys listed below:
   * {stepIndex, parentId, hasLinkedStep, linkedStepType}
   */
  static deleteStep(stepInfo) {
    const { stepIndex, parentId, hasLinkedStep, linkedStepType } = stepInfo;
    const stepData = this.steps[stepIndex];
    const stepId = stepData.id;
    const stepIdsToRemove = [stepId];

    /* If a split element node or a node linked to it is getting deleted,
     *  delete whole tree below it
     */
    if (
      SPLIT_TYPE_ACTIONS.includes(stepData.type) ||
      SPLIT_TYPE_ACTIONS.includes(linkedStepType)
    ) {
      const stepIdQueue = [stepId];

      while (stepIdQueue.length !== 0) {
        let tempId = stepIdQueue.shift();
        const childIds = this.adjacencyMatrix[tempId] || [];
        stepIdsToRemove.push(...childIds);
        stepIdQueue.push(...childIds);
        delete this.adjacencyMatrix[tempId];
      }

      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId]?.map(
        (childId) => (childId === stepId ? null : childId)
      );
    } else {
      const childId = (this.adjacencyMatrix[stepId] || [])[0];

      const subChildId =
        hasLinkedStep && DIRECT_CHILD_ACTION_TYPES.includes(linkedStepType)
          ? (this.adjacencyMatrix[childId] || [])[0]
          : null;

      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId].map(
        (existingChildId) =>
          existingChildId === stepId ? subChildId || childId : existingChildId
      );

      delete this.adjacencyMatrix[stepId];

      if (hasLinkedStep) {
        stepIdsToRemove.push(childId);
        delete this.adjacencyMatrix[childId];
      }
    }

    this.steps = this.steps.filter(
      (step) => !stepIdsToRemove.includes(step.id)
    );

    this.generateUIJson();
  }

  static getAllSteps() {
    return Utility.makeCopyOfObject(this.steps);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  /* Method for handling existing automations,
  saved without adjacency matrix */
  static generateAdjacencyMatrix() {
    const steps = this.steps;
    const adjacencyMatrix = {};

    if (steps.length) {
      const newSteps = steps.map((step) => ({
        ...step,
        id: getRandomAlphaNumericString(24)
      }));

      for (let counter = 1; counter < newSteps.length; counter++) {
        const sourceStepId = newSteps[counter - 1].id;
        const targetStepId = newSteps[counter].id;
        adjacencyMatrix[sourceStepId] = [targetStepId];
      }

      this.steps = newSteps;
    }

    return adjacencyMatrix;
  }

  static setAdjacencyMatrix(parentId, childId, isSplitAction, childIndex) {
    if (parentId) {
      this.adjacencyMatrix[parentId] = this.adjacencyMatrix[parentId] || [];

      const existingChildId = this.adjacencyMatrix[parentId][childIndex];

      this.adjacencyMatrix[parentId][childIndex] = childId;

      /* For split action there can be 2 paths:
          path from 0th index is taken when split condition evaluates to true (YES),
          path from 1st index is taken when split condition evaluates to false (NO)
      */
      this.adjacencyMatrix[childId] = isSplitAction
        ? [existingChildId, null]
        : [existingChildId];
    } else {
      this.adjacencyMatrix[childId] = isSplitAction ? [null, null] : [null];
    }
  }

  static getAdjacencyMatrix() {
    return Utility.makeCopyOfObject(this.adjacencyMatrix);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static generateUIJson() {
    this.uiJson.wingSpan = {};

    const { radiusLeft, radiusRight } = this.populateWorkflowNodesWingSpan(
      this.steps[0]?.id
    );

    this.uiJson.outerRadiusFromLeft = radiusLeft;
    this.uiJson.outerRadiusFromRight = radiusRight;
  }

  /*
    This method evaluates and populates inner radius for each side of a `SPLIT_WORKFLOW` type node.
  */
  static populateWorkflowNodesWingSpan(stepId) {
    if (stepId === null) return { radiusLeft: 0, radiusRight: 0 };

    let parentId = null,
      children = [stepId];
    /* for finding the next SPLIT_WORKFLOW type node */
    while (children && children.length < 2) {
      parentId = children[0];
      children = this.adjacencyMatrix[parentId];
    }

    if (children) {
      const {
        radiusLeft: outerRadiusFromLeftWing,
        radiusRight: innerRightRadiusFromLeftWing
      } = this.populateWorkflowNodesWingSpan(children[0]);

      const {
        radiusLeft: innerLeftRadiusFromRightWing,
        radiusRight: outerRadiusFromRightWing
      } = this.populateWorkflowNodesWingSpan(children[1]);

      this.uiJson.wingSpan[parentId] = [
        innerRightRadiusFromLeftWing + 1,
        innerLeftRadiusFromRightWing + 1
      ];

      let radiusLeft =
        Math.max(outerRadiusFromLeftWing, innerRightRadiusFromLeftWing) + 1;
      let radiusRight =
        Math.max(innerLeftRadiusFromRightWing, outerRadiusFromRightWing) + 1;

      return { radiusLeft, radiusRight };
    } else {
      return { radiusLeft: 0, radiusRight: 0 };
    }
  }

  static setupSplitActionConfigurations() {
    const parentKeyStepTypeMapping = {};

    this.steps = this.steps.map((stepData) => {
      const newStepData = Utility.makeCopyOfObject(stepData);
      parentKeyStepTypeMapping[newStepData.id] = newStepData.type;

      if (SPLIT_TYPE_ACTIONS.includes(newStepData.type)) {
        const splitPathChildren = this.adjacencyMatrix[newStepData.id];
        newStepData.successKey = splitPathChildren[0] || null;
        newStepData.failureKey = splitPathChildren[1] || null;
        newStepData.logicalOperator = "and";
      }

      return newStepData;
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static isDataAvailable() {
    return !(this.automationData === null || this.automationData === undefined);
  }

  static setData(response) {
    this.automationData = response;
    if (this.isDataAvailable()) {
      const jsonData = this.getJSONData();
      this.setNewAutomationData(jsonData);
    } else {
      this.resetAllData();
    }
  }

  static setNewAutomationData(jsonData, isCopied = false) {
    this.steps = jsonData.steps;
    this.adjacencyMatrix = jsonData.adjacencyMatrix;

    /* refreshing step ids in case of copied workflow */
    if (isCopied && this.steps) {
      const oldNewStepIdMap = new Map();
      this.steps = this.steps.map((step) => {
        const newStepId = getRandomAlphaNumericString(24);
        step.id && oldNewStepIdMap.set(step.id, newStepId);

        return {
          ...step,
          id: newStepId
        };
      });

      /* Updating previous id's in step configurations */
      this.steps = this.steps.map((step) => {
        try {
          switch (step.type) {
            case AUTOMATION_ACTIONS.WAIT_TILL:
            case AUTOMATION_ACTIONS.SPLIT_WORKFLOW:
              step.configuration = step.configuration.map((config) => {
                const fields = config.fields.map((field) => {
                  if (
                    Utility.isEmptyObject(field.nodeKey) ||
                    !oldNewStepIdMap.get(field.nodeKey)
                  ) {
                    return field;
                  }

                  return {
                    ...field,
                    nodeKey: oldNewStepIdMap.get(field.nodeKey)
                  };
                });

                return {
                  ...config,
                  fields
                };
              });
              break;
            default:
          }
        } catch (err) {
          console.log(
            "Error while updating step ids in duplicate automation",
            err
          );
        }

        return step;
      });

      if (this.adjacencyMatrix) {
        const newAdjacencyMatrix = {};

        Object.keys(jsonData.adjacencyMatrix).forEach((parentOldStepId) => {
          const parentNewStepId = oldNewStepIdMap.get(parentOldStepId);

          if (Utility.isEmptyObject(parentNewStepId)) return;

          const childOldStepIds = jsonData.adjacencyMatrix[parentOldStepId];
          const childNewStepIds = childOldStepIds?.map(
            (childOldStepId) => oldNewStepIdMap.get(childOldStepId) || null
          );

          newAdjacencyMatrix[parentNewStepId] = childNewStepIds;
        });

        this.adjacencyMatrix = newAdjacencyMatrix;
      }
    }

    if (!this.adjacencyMatrix) this.generateAdjacencyMatrix();

    this.generateUIJson();
  }

  static getData() {
    return this.automationData;
  }

  static resetAllData() {
    this.steps = [];
    this.adjacencyMatrix = {};
    this.uiJson = {
      wingSpan: {},
      outerRadiusFromLeft: 0
    };
  }
  ////////////////////////////////////////////////////////////////////////////////////

  static getRecordId() {
    if (!this.isDataAvailable()) {
      return null;
    }

    return this.automationData.data._id;
  }
  ////////////////////////////////////////////////////////////////////////////////////

  static getAutomationName() {
    if (!this.isDataAvailable()) {
      return "New Automation";
    }

    return this.automationData.data.cells[
      this.getColumnId(COLUMN_CODE.AUTOMATION.NAME)
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////

  static getStatus() {
    if (!this.isDataAvailable()) {
      return false;
    }

    let status =
      this.automationData.data.cells[
        this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)
      ];

    status = JSON.stringify(status);

    return status === "[1]";
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getJSONData() {
    if (!this.isDataAvailable()) {
      return [];
    }

    let jsonData =
      this.automationData.data.cells[
        this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)
      ];

    return Utility.decodeJSON(jsonData);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getRequestToSave(_data) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.NAME)] = _data.name;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = _data.json;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      STATUS_OPTIONS.ACTIVE;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.CONNECTION_ID)] =
      this.getConnectionId();
    return data;
  }

  static getRequestToUpdate(_data) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.JSON_DATA)] = _data.json;
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      STATUS_OPTIONS.ACTIVE;
    return data;
  }

  static getRequestToUpdateStatus(isActive) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.AUTOMATION.STATUS)] =
      isActive === true ? STATUS_OPTIONS.ACTIVE : STATUS_OPTIONS.IN_ACTIVE;

    return data;
  }

  static getColumnId(columnCode) {
    return TableManger.getColumnId(TABLES.AUTOMATION, columnCode);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static fetchPageDataById(id, callback) {
    AutomationService.getData(
      id,
      (response) => {
        this.setData({ data: response });
        callback && callback();
      },
      (error) => {}
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static setConnectionId(id) {
    this.connectionId = id;
  }
  static getConnectionId() {
    return this.connectionId;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static getSplitStepDimensions(splitActionId, splitLevel) {
    const gutter = Math.floor(SPLIT_GUTTER / splitLevel);

    const splitWing = this.uiJson?.wingSpan[splitActionId] || [1, 1];
    const leftWingPadding = (splitWing[0] - 1) * STEP_CARD_WIDTH + gutter;
    const rightWingPadding = (splitWing[1] - 1) * STEP_CARD_WIDTH + gutter;

    return {
      stepWidth: STEP_CARD_WIDTH + gutter,
      leftWingPadding: leftWingPadding,
      rightWingPadding: rightWingPadding,
      leftWingHangerWidth: (splitWing[0] - 1 || 0.5) * STEP_CARD_WIDTH + gutter,
      rightWingHangerWidth:
        (splitWing[1] - 1 || 0.5) * STEP_CARD_WIDTH + gutter,
      justifyWings:
        leftWingPadding === rightWingPadding
          ? "center"
          : leftWingPadding - rightWingPadding > 0
            ? "end"
            : "start"
    };
  }

  static getForms = () => {
    return this.fbForms;
  };

  static getPages = () => {
    return this.fbPages;
  };

  static setForms = (data) => {
    this.fbForms = data;
  };

  static setPages = (data) => {
    this.fbPages = data;
  };

  static getOptionsToAssignDealName() {
    return TableManger.getTableColumns(TABLES.CONTACT)
      .filter(this.getDealNameContactColumnFilterFunc())
      ?.map((column) => ({ ...column, name: `Contact ${column.name}` }));
  }

  static getDealNameContactColumnFilterFunc = () => {
    return (column) => {
      let flag = false;
      if (
        column.uiVisible &&
        [INPUT_TYPE.TEXT, INPUT_TYPE.EMAIL].includes(column.type)
      )
        flag = true;
      return flag;
    };
  };
}
